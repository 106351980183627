import { FormRadioSelect } from '../../../../../../../components';
import { YesNoAnswer } from '../../../../../../../types';
import { AddOnCheckboxOption } from '../../types';
import { BREAKDOWN_NOT_NEEDED_ID } from '../../../AddOns.utils';

export const addOnCheckBoxOptions = (testId: string, addOnId: string) => [
  {
    id: YesNoAnswer.YES,
    name:
      addOnId !== BREAKDOWN_NOT_NEEDED_ID
        ? 'I need this'
        : 'Choose option',
    testId: `${testId}_i-need-this`,
  },
  {
    id: YesNoAnswer.NO,
    name: 'No thanks',
    testId: `${testId}_no-thanks`,
  },
];

type AddOnCheckboxCardRadioProps = {
  addOnId: string;
  onChange: (option: AddOnCheckboxOption | any) => void;
  name: string;
  disabled?: boolean;
  testId?: string;
};
export const AddOnCheckboxCardRadio = ({
  onChange,
  name,
  testId = 'addon-card-radio',
  disabled,
  addOnId,
}: AddOnCheckboxCardRadioProps) => {
  return (
    <div>
      <FormRadioSelect
        id={`${testId}_options`}
        testId={`${testId}_options`}
        name={name}
        options={addOnCheckBoxOptions(`${testId}_options`, addOnId)}
        onChangeOptional={onChange}
        disabled={disabled}
      />
    </div>
  );
};
