import { Card } from '@dayinsure/components';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { FeesAndChargesRow as Row } from './FeesAndChargesRow';
import { CostDto, FeesForDisplayDto } from '../../../../api/v1';

type FeesAndChargesProps = {
  testId: string;
  feesForDisplay?: FeesForDisplayDto;
  className?: string;
  missedPaymentFee?: CostDto;
  cancellationFee?: CostDto;
};

export const FeesAndCharges = ({
  testId,
  className,
  feesForDisplay,
  missedPaymentFee,
  cancellationFee,
}: FeesAndChargesProps) => {
  const renderCommonFees = useMemo(() => {
    return (
      <>
        {feesForDisplay?.cancellationFeeBeforeAndDuringCoolingOff && (
          <Row
            text="Cancellation within 14 days of buying or renewing your policy"
            value={Number(
              feesForDisplay?.cancellationFeeBeforeAndDuringCoolingOff.amount
            )}
          />
        )}
        {feesForDisplay?.cancellationFeeAfterCoolingOff && (
          <Row
            text="Cancellation after 14 days of buying or renewing your policy"
            value={Number(feesForDisplay?.cancellationFeeAfterCoolingOff?.amount)}
          />
        )}
        {feesForDisplay?.administrationFee && (
          <Row
            text="Set-up fee (non-refundable)"
            value={Number(
              feesForDisplay?.administrationFee.amount
            )}
          />
        )}
        {feesForDisplay?.mtaFee && (
          <Row text="Amendment fee" value={Number(feesForDisplay?.mtaFee?.amount)} />
        )}
        {!feesForDisplay?.missedPaymentFee && missedPaymentFee?.amount && (
          <Row text="Missed payment fee" value={Number(missedPaymentFee?.amount)} />
        )}
        {!feesForDisplay?.cancellationFee && cancellationFee?.amount && (
          <Row
            text="Cancelling and then reinstating your finance agreement"
            value={Number(cancellationFee?.amount)}
          />
        )}
        {feesForDisplay?.missedPaymentFee && (
          <Row text="Missed payment fee" value={Number(feesForDisplay?.missedPaymentFee?.amount)} />
        )}
        {feesForDisplay?.cancellationFee && (
          <Row
            text="Cancelling and then reinstating your finance agreement"
            value={Number(feesForDisplay?.cancellationFee?.amount)}
          />
        )}
      </>
    );
  }, [
    feesForDisplay,
    cancellationFee?.amount,
    missedPaymentFee?.amount,
  ]);
  return (
    <Card
      classNames={clsx('mt-6 lg:mt-8 bg-content-background-prominent', className)}
      testId={`${testId}_fees-and-charges_card`}
    >
      <h3 className="mb-6 font-raleway text-lg">Other fees and charges</h3>
      {renderCommonFees}
    </Card>
  );
};
