import clsx from 'clsx';
import { Card, ContentContainer, Icon } from '@dayinsure/components';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { useParams } from 'react-router-dom';
import { PoliciesPaths } from '../../../../routes';
import { PolicySummaryCard } from '../../../../components';
import {
  useMotorPolicySummaryQuery,
  usePolicyQuery,
  useResetFormState,
} from '../../../../hooks';
import { PolicyDocuments } from '../../../../components/PolicyDocuments';
import { CardWithLogin } from '../../../../components/CardWithLogin';
import { PolicyPendingChangesMessage } from '../../PolicyInfo/PolicyInfoPendingChanges';

const testId = 'changes-complete';
export const ChangesComplete = () => {
  useResetFormState();
  const { quoteId, id } = useParams<{ quoteId: string; id: string }>();
  const { data: policy } = usePolicyQuery(id);
  const { data } = useMotorPolicySummaryQuery(quoteId);
  const motorQuote = data?.[0];

  return (
    <div className="mb-8 w-full md:mb-16">
      <ContentContainer>
        <h1
          className="mt-8 text-xl text-center md:mt-12 text-main-content-1 lg:mt-15"
          data-testid={`${testId}_title`}
        >
          Changes complete
        </h1>
        <h2
          className="my-8 text-lg text-center md:mt-12 lg:mt-12 text-main-content-1"
          data-testid={`${testId}_subtitle`}
        >
          Thank you for using us for your car insurance
        </h2>
        {policy?.pendingChanges && policy?.pendingChanges?.effectiveDateTimeUtc && (
          <PolicyPendingChangesMessage
            testId={testId}
            changesStartDateUtc={policy?.pendingChanges?.effectiveDateTimeUtc}
            policyVersion={policy?.pendingChanges?.policyVersion}
            customNavLink={`../../../${PoliciesPaths.UpcomingChanges}`}
          />
        )}
        {motorQuote && (
          <PolicySummaryCard
            plateNo={motorQuote.vehicleRegistration || ''}
            carDetails={motorQuote.vehicleDescription || ''}
            progress={motorQuote.policyProgress || 0}
            buttonLink={`/${PoliciesPaths.Policies}/${id}`}
            testId={testId}
            activityDate={`Ends ${formatInUKLocalTime(
              'dd/MM/yyyy',
              motorQuote.endDate
            )} at ${formatInUKLocalTime('HH:mm', motorQuote.endDate)}`}
            className="bg-content-background-prominent"
          />
        )}
        <CardWithLogin testId={`${testId}_info-documents`} />
        {motorQuote?.id && (
          <PolicyDocuments
            className="mt-8 lg:mt-12"
            testId={testId}
            policyId={motorQuote?.id}
          />
        )}
        <Card
          classNames={clsx('flex items-center', 'mb-8', 'mt-6', 'lg:mt-8')}
          paddingLevel="small"
          testId={`${testId}_mulsanne-claims`}
        >
          <Icon className="text-main-content-1" size="2rem" name="phone" />
          <div className="ml-6 text-main-content-1">
            All claims are handled by Mulsanne. Should you need to make a claim on your
            policy, please call Mulsanne on&nbsp;
            <a href="tel:03332076598" className="font-semibold underline text-link">
              0333 2076598
            </a>
            .
          </div>
        </Card>
        <Card
          classNames={clsx('flex items-center', 'mb-8', 'mt-6', 'lg:mt-8')}
          paddingLevel="small"
          testId={`${testId}_RAC-phone`}
        >
          <Icon className="text-main-content-1" size="2rem" name="phone" />
          <div className="ml-6 text-main-content-1">
            If you are unfortunate enough to break down please call RAC on
            <br />{' '}
            <a href="tel:XXXXXXXXXXX" className="font-semibold underline text-link">
              XXXX XXX XXXX
            </a>
            .
          </div>
        </Card>
      </ContentContainer>
    </div>
  );
};
