import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { numberInputOnWheelPreventChange } from '@dayinsure/shared';
import {
  FormTextField as FormTextFieldView,
  IconNames,
  TextFieldProps,
} from '@dayinsure/components';
import clsx from 'clsx';
import { useFocusOnError } from '../../../hooks';
import { transformForCommaAndNoCommaValue } from '../../../helpers';

export type FormNumFieldProps = {
  label: {
    icon: IconNames;
    text: string;
  };
  testId: string;
  tooltip?: string;
  tooltipHint?: string;
  tooltipCloseButton?: string;
  spacingClassName?: string;
  milesPerWeek?: number;
} & Omit<TextFieldProps, 'label' | 'error' | 'onChange'>;

export const FormNumField = ({
  testId,
  label,
  name,
  placeholder,
  tooltipHint = 'hint',
  tooltipCloseButton = 'Close',
  onBlur,
  type,
  milesPerWeek,
  ...props
}: FormNumFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const fieldRef = useRef<HTMLInputElement>(null);
  const [numValue, setNumValue] = useState('');
  const [field, meta] = useField({ name });
  useFocusOnError({ fieldRef, name });
  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    field.onBlur({ target: { name: field.name } });
    onBlur?.(event);
  };

  const handleWheelEvent =
    type === 'number' ? numberInputOnWheelPreventChange : undefined;

  const handleNumField = ({ target, nativeEvent }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value;
    const currentSign = (nativeEvent as InputEvent)?.data;
    const currentCharNaN = Number.isNaN(Number(currentSign));
    if (!currentCharNaN) {
      const { formValue, valueWithComa } = transformForCommaAndNoCommaValue(value);
      setNumValue(valueWithComa);
      setFieldValue(name, formValue);
    }
  };

  useEffect(() => {
    const { value } = field;
    if (value) {
      const { valueWithComa } = transformForCommaAndNoCommaValue(String(value));
      setNumValue(valueWithComa);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <FormTextFieldView
        {...props}
        {...field}
        type={type}
        ref={fieldRef}
        error={meta.error}
        testId={testId}
        label={label}
        name={name}
        placeholder={placeholder}
        touched={meta.touched}
        tooltipHint={tooltipHint}
        tooltipCloseButton={tooltipCloseButton}
        value={field.value ?? ''}
        onBlur={handleBlur}
        onWheel={handleWheelEvent}
      >
        <div className="relative">
          <input
            data-testid={testId}
            className={clsx(
              'bg-transparent rounded-lg border-2 focus:outline-none transition-colors border-main-content-3 focus:border-main-content-1 text-main-content-1 placeholder:text-main-content-2 field-base',
              { 'opacity-40': props.disabled },
              { 'border-error-border': meta.error && meta.touched },
              props.className
            )}
            type="text"
            value={numValue}
            onChange={handleNumField}
            onBlur={handleBlur}
            placeholder={placeholder}
            ref={fieldRef}
          />
          {milesPerWeek && (
            <div className="flex absolute inset-y-0 right-6 my-auto">
              <span className="flex items-center opacity-60 'text-main-content-2">
                that’s roughly {milesPerWeek} miles a week
              </span>
            </div>
          )}
        </div>
      </FormTextFieldView>
    </>
  );
};
