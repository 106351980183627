import {
  CoverMtaFormData,
  DriversPolicyFormData,
  IsSameRegPlateFormData,
  MinMaxStartDateFormData,
  MtaJourneyFormData,
  NewCostFormData,
  NewRegFormData,
  PolicyChangeFormData,
  VehicleDetailsMtaFormData,
} from '../../types/mtaJourneyForm';
import {
  CarSecurityFormData,
  PaymentFieldsFormData,
  UsualPaymentFrequencyFormData,
} from '../../types';
import { vehicleDetailsInitialData } from '../quoteJourney';

export const policyChangedFormInitialData: PolicyChangeFormData = {
  policyChange: {
    changeCar: false,
    changeReg: false,
    changeDrivers: false,
  },
};

export const coverInitialFormData: Partial<CoverMtaFormData> = {
  cover: {
    type: null,
    startDateTimeUtc: null,
    exactStartHour: null,
    exactStartMinute: null,
    paymentType: { id: 'ANN', name: 'Annually' },
    noClaimsHistory: { yearsNoClaimsExperience: null, yearsNoClaimsBonus: null },
    addOns: [],
    breakdownAddon: null,
  },
};

export const driversInitialFormData: DriversPolicyFormData = {
  drivers: {
    drivers: [],
    driversToRemove: [],
    driversToAdd: [],
    driversToUpdate: [],
    startDateTimeUtc: null,
    mainDriver: null,
    hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: null,
  },
};

export const vehicleDetailsInitialFormData: VehicleDetailsMtaFormData = {
  vehicle: {
    regPlate: '',
    ...vehicleDetailsInitialData,
  },
};

export const newRegFormInitialData: NewRegFormData = {
  newReg: {
    notChanging: false,
    vehicleId: null,
    regPlateValid: false,
    regPlate: '',
    abiCode: '',
  },
};

export const paymentFieldsFormData: PaymentFieldsFormData = {
  isRefund: false,
  encryptedCardNumber: null,
  cardholderName: null,
  encryptedExpiryDate: null,
  encryptedExpiryMonth: null,
  encryptedExpiryYear: null,
  encryptedSecurityCode: null,
  paymentDay: null,
  accountHolder: '',
  bankAccountNumber: '',
  sortCode: '',
  isDirectDebitConfirmed: false,
  isMonthlyImportantInformationConfirmed: false,
  isAnnualImportantInformationConfirmed: false,
  isUnsupportedCardBrand: false
};

export const usualPaymentFrequencyInitialFormData: UsualPaymentFrequencyFormData = {
  usualPaymentFrequency: {
    code: null,
  },
};

export const carSecurityInitialFormData: CarSecurityFormData = {
  carSecurity: {
    isEndorsement202: false,
    isEndorsement208: false,
    approvedTracker: null,
    antiCloneImmobiliser: null,
    immobiliser: null,
    installationProof: null,
  },
};

export const minStartDateFormData: MinMaxStartDateFormData = {
  minStartDate: null,
  maxStartDate: null,
};

export const isSameRegPlateInitialFormData: IsSameRegPlateFormData = {
  isSameRegPlate: false,
};

export const newCostAgreement: NewCostFormData = {
  newCostAgreement: false,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const mtaJourneyInitialFormData: MtaJourneyFormData = {
  ...policyChangedFormInitialData,
  ...coverInitialFormData,
  ...driversInitialFormData,
  ...vehicleDetailsInitialFormData,
  ...paymentFieldsFormData,
  ...usualPaymentFrequencyInitialFormData,
  ...newRegFormInitialData,
  ...carSecurityInitialFormData,
  ...minStartDateFormData,
  ...isSameRegPlateInitialFormData,
  ...newCostAgreement,
};
