import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  CardValueWarningCard,
  ExcessesCard,
  PaymentMobileFooter,
  QuoteJourneyLayout,
} from '../../../components';
import { QuoteDetailsReview } from './QuoteDetailsReview';
import { ReviewPageWrapper } from './ReviewPageWrapper';
import { Documents } from '../../../components/Documents';
import { CreateAccount } from './CreateAccount';
import { ConditionsCard } from '../../../components/Quote';
import { ContactDetails } from '../YourDetails/ContactDetails';
import { QuoteJourneyFormData } from '../../../types';
import { FeesAndCharges } from './FeesAndCharges';
import { useQuoteQuery } from '../../../hooks';
import { MarketingPreferences } from '../YourDetails/MarketingPreferences';
import { AcceptStatement } from './AcceptStatement';
import {
  carValueIsLessOrEqualThanExcesses,
  getCurrentPaymentPlan,
} from '../../../helpers';

export const testId = 'review';

export const Review = () => {
  const {
    values: {
      proposer: {
        telephoneNumber: { mobile, landline },
      },
      usualPaymentFrequency,
      reviewUpdate,
    },
    setFieldValue,
  } = useFormikContext<QuoteJourneyFormData>();
  const { quoteId } = useParams<{ quoteId: string }>();
  const { data: quote } = useQuoteQuery(quoteId);
  useEffect(() => {
    const contactDetailsNotAvailable = !mobile && !landline;
    setFieldValue('reviewUpdate', contactDetailsNotAvailable);
    return () => setFieldValue('reviewUpdate', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectedPaymentPlan = getCurrentPaymentPlan(
    usualPaymentFrequency,
    quote?.paymentPlans
  );
  const displayCarValueWarningCard = carValueIsLessOrEqualThanExcesses(
    quote?.excesses,
    quote?.vehicle?.selfDeclaredVehicleValue
  );

  return (
    <QuoteJourneyLayout>
      <ReviewPageWrapper testId={testId}>
        <QuoteDetailsReview testId={testId} />
        <ConditionsCard testId={testId} />
        <Documents testId={testId} />
        <FeesAndCharges
          testId={testId}
          feesForDisplay={quote?.insurancePrice?.feesForDisplay}
          missedPaymentFee={selectedPaymentPlan.missedPaymentFee}
          cancellationFee={selectedPaymentPlan.cancellationFee}
        />
        <ExcessesCard testId={testId} />
        {displayCarValueWarningCard && <CardValueWarningCard testId={testId} />}
        <CreateAccount testId={testId} />
        {reviewUpdate && (
          <>
            <ContactDetails testId={`${testId}_contact-details`} noEmailField />
            <MarketingPreferences testId={`${testId}_marketing-preferences`} />
          </>
        )}
        <AcceptStatement testId={testId} />
      </ReviewPageWrapper>
      <PaymentMobileFooter />
    </QuoteJourneyLayout>
  );
};
