import { Card } from '@dayinsure/components';
import { AddOnModelDto, CoverDto, NoClaimsHistoryDto } from '../../../../api/v1';
import { getClaimsBonusDescription } from '../../../../helpers';

type PolicyInfoCoversProps = {
  testId?: string;
  cover?: CoverDto;
  addons?: AddOnModelDto[] | null;
  noClaimsHistory?: NoClaimsHistoryDto | null;
};

export const PolicyInfoCovers = ({
  testId,
  cover,
  addons,
  noClaimsHistory,
}: PolicyInfoCoversProps) => {
  const displayAddOns = addons?.length !== 0;
  const noClaimBonusPronun = getClaimsBonusDescription(noClaimsHistory?.yearsNoClaimsBonus);
  return (
    <section>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent mb-6"
        testId={`${testId}_cover`}
      >
        <h2 className="mb-4 font-raleway text-lg">Cover</h2>
        <Card.CardListItem
          label="Cover type"
          icon="dpolicies"
          testId={`${testId}_cover-type_list-item`}
        >
          {cover?.type?.description}
        </Card.CardListItem>

        {displayAddOns && (
          <Card.CardListItem
            label="Add-ons"
            icon="addons"
            testId={`${testId}_cover_addons_list-item`}
          >
            {addons?.map((addon, index) => (
              <div key={`${testId}_addon-${index.toString()}`}>
                • {addon.type?.description || addon.type?.code}
              </div>
            ))}
          </Card.CardListItem>
        )}
        {typeof noClaimsHistory?.yearsNoClaimsBonus === 'number' && (
          <Card.CardListItem
            label="Number of years No Claims Bonus"
            icon="date"
            testId={`${testId}_no-claims-discount-years_list_item`}
          >
            {`${noClaimsHistory?.yearsNoClaimsBonus} ${noClaimBonusPronun}`}
          </Card.CardListItem>
        )}
      </Card>
    </section>
  );
};
