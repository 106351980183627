import { ContentContainer } from '@dayinsure/components';
import { useParams } from 'react-router-dom';
import { formatInUKLocalTime } from '@dayinsure/shared';
import { useQuoteQuery } from '../../../hooks';
import {
  CardWithProcessingPayment,
  ContactCards,
  Header,
  Footer,
  PolicySummaryCard,
} from '../../../components';

const testId = 'purchase-complete-delay';
export const PurchaseCompleteDelay = () => {
  const { quoteId } = useParams<{ quoteId: string }>();
  const { data } = useQuoteQuery(quoteId);
  return (
    <div className="overflow-auto fixed top-0 mb-8 w-full h-full bg-white md:mb-16">
      <Header />
      <ContentContainer>
        <h1
          className="mt-8 text-xl text-center md:mt-12 text-main-content-1 lg:mt-15"
          data-testid={`${testId}_title`}
        >
          Purchase complete
        </h1>
        <h2
          className="my-8 text-lg text-center md:mt-12 lg:mt-12 text-main-content-1"
          data-testid={`${testId}_subtitle`}
        >
          Thank you for using us for your car insurance
        </h2>
        <PolicySummaryCard
          plateNo={data?.vehicle?.registrationNumber || ''}
          carDetails={`${data?.vehicle?.make} ${data?.vehicle?.model}` || ''}
          progress={0}
          hideProgress
          testId={testId}
          activityDate={`Starts ${formatInUKLocalTime(
            'dd/MM/yyyy',
            data?.effectiveDateTimeUtc
          )} at ${formatInUKLocalTime('HH:mm', data?.effectiveDateTimeUtc)}`}
          className="bg-content-background-prominent"
        />
        <CardWithProcessingPayment testId={testId} />
        <ContactCards testId={testId} className="mt-12" showLinks={false} />
      </ContentContainer>
      <Footer />
    </div>
  );
};
