import { MarketConsentType } from '../../api/v1';
import {
  CarSecurityFormData,
  CarSecurityPolicyConditionsFormData,
  CoverFormData,
  DriverFormData,
  DriversPageFormData,
  ExcessesFormData,
  HasAnyDriverHadPreviousInsuranceDeclinedOrCancelledFromData,
  JourneyStartPositionFormData,
  PaymentFieldsFormData,
  ProposerFormData,
  QuoteDateTimeUtcFormData,
  QuoteJourneyFormData,
  SignUpFormData,
  UsualPaymentFrequencyFormData,
  Vehicle,
  VehicleDetailsFormData,
  VehicleOwnership,
  WasEmailChangedAfterCreationFormData,
} from '../../types';
import { EXCESSES } from '../../constants/payments';

const journeyStartPositionInitialFormData: JourneyStartPositionFormData = {
  journeyStartPosition: null,
};

const vehicleUsageInitialFormData = {
  type: null,
  annualMileage: null,
  businessMileage: null,
};

const vehicleOwnershipInitialFormData: VehicleOwnership = {
  isPurchased: null,
  selfDeclaredDateOfPurchase: { day: '', month: '', year: '', parsedDate: '' },
  registerdKeeper: null,
  legalOwner: null,
};

const vehicleLocationInitialFormData = {
  daytimeLocation: null,
  overnightLocation: null,
  keptHome: null,
  overnightLocationPostcode: '',
};

export const vehicleDetailsInitialData: Vehicle = {
  registrationNumber: '',
  type: null,
  abiCode: null,
  make: null,
  model: null,
  steeringWheelSide: null,
  numberOfSeats: null,
  isImported: null,
  importType: null,
  manufactureYear: null,
  manufactureStartYear: null,
  manufactureEndYear: null,
  bodyType: null,
  numberOfDoors: null,
  cubicCapicityInLitres: null,
  transmission: null,
  weightInKg: null,
  primaryFuelType: null,
  secondaryFuelType: null,
  vin: null,
  trackingDevice: {
    hasTrackingDevice: null,
    code: null,
  },
  alarmImmobiliser: null,
  selfDeclaredVehicleValue: null,
  isCarModified: null,
  isAddingModification: false,
  modifications: [],
  areDetailsCorrect: false,
  usage: vehicleUsageInitialFormData,
  ownership: vehicleOwnershipInitialFormData,
  location: vehicleLocationInitialFormData,
};

const vehicleDetailsInitialFormData: VehicleDetailsFormData = {
  vehicle: vehicleDetailsInitialData,
};

const proposerInitialFormData: ProposerFormData = {
  proposer: {
    title: null,
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    maritalStatus: null,
    marketingPreferences: [
      { id: MarketConsentType.EMAIL, name: 'Email', checked: false },
      { id: MarketConsentType.SMS, name: 'SMS', checked: false },
      { id: MarketConsentType.TELEPHONE, name: 'Telephone', checked: false },
      { id: MarketConsentType.POST, name: 'Post', checked: false },
    ],
    telephoneNumber: {
      mobile: '',
      landline: '',
    },
    email: '',
    homePostcode: '',
    homeAddress: null,
    address: null,
    isHomeOwner: null,
    numberOfVehiclesInHousehold: null,
    childrenUnder16InHousehold: null,
  },
};

const driversPageInitialFormData: DriversPageFormData = {
  mainDriver: null,
};

export const driversInitialFormData: { drivers: DriverFormData[] } = {
  drivers: [],
};

export const coverInitialFormData: CoverFormData = {
  cover: {
    type: null,
    startDateTimeUtc: null,
    paymentType: { id: 'ANN', name: 'Annually' },
    noClaimsHistory: { yearsNoClaimsExperience: null, yearsNoClaimsBonus: null },
    addOns: [],
    breakdownAddon: null,
    optionalAddOnsValidation: [],
  },
};

export const carSecurityInitialFormData: CarSecurityFormData = {
  carSecurity: {
    isEndorsement202: false,
    isEndorsement208: false,
    approvedTracker: null,
    antiCloneImmobiliser: null,
    immobiliser: null,
    installationProof: null,
  },
};

export const excessesInitialFormData: ExcessesFormData = {
  excesses: {
    voluntaryAmounts: [
      {
        total: null,
        type: {
          code: EXCESSES.policy,
        },
      },
    ],
  },
};

export const hasAnyDriverHadPreviousInsuranceDeclinedOrCancelledInitialFormData: HasAnyDriverHadPreviousInsuranceDeclinedOrCancelledFromData =
  {
    hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: null,
  };

const signUpInitialFormData: SignUpFormData = {
  isCreateAccountDisplayed: true,
  isAccountCreated: false,
  signUp: {
    password: '',
    retypePassword: '',
    reaptcha: '',
  },
};

export const usualPaymentFrequencyInitialFormData: UsualPaymentFrequencyFormData = {
  usualPaymentFrequency: {
    code: null,
  },
};

export const paymentFieldsFormData: PaymentFieldsFormData = {
  isRefund: false,
  encryptedCardNumber: null,
  cardholderName: null,
  encryptedExpiryDate: null,
  encryptedExpiryMonth: null,
  encryptedExpiryYear: null,
  encryptedSecurityCode: null,
  paymentDay: null,
  accountHolder: '',
  bankAccountNumber: '',
  sortCode: '',
  isDirectDebitConfirmed: false,
  isMonthlyImportantInformationConfirmed: false,
  isAnnualImportantInformationConfirmed: false,
  isUnsupportedCardBrand: false,
};

export const productFieldsFormData = {
  product: {
    name: 'motor',
    version: 1,
  },
};

const carSecurityPolicyConditionsFormData: CarSecurityPolicyConditionsFormData = {
  endorsementsConfirmCheckbox: false,
};

const policyConditionsFormData = {
  policyConditions: false,
};

const endorsementsFormData = {
  endorsements: null,
};

const updateContactDetailsFormData = {
  reviewUpdate: false,
};

const protectNoClaimsDiscountFormData = {
  protectNoClaimsDiscount: null,
};

const isAccountHolderFormData = {
  isAccountHolder: null,
};

const correlationIdFormData = {
  policyCorrelationId: null,
};

const quoteDateTimeUtcData: QuoteDateTimeUtcFormData = {
  quoteDateTimeUtc: null,
};

const wasEmailChangedAfterCreationFormData: WasEmailChangedAfterCreationFormData = {
  wasEmailChangedAfterCreation: null,
};

export const quoteJourneyInitialFormData: QuoteJourneyFormData = {
  ...journeyStartPositionInitialFormData,
  ...vehicleDetailsInitialFormData,
  ...proposerInitialFormData,
  ...driversPageInitialFormData,
  ...driversInitialFormData,
  ...coverInitialFormData,
  ...carSecurityInitialFormData,
  ...carSecurityPolicyConditionsFormData,
  ...excessesInitialFormData,
  ...hasAnyDriverHadPreviousInsuranceDeclinedOrCancelledInitialFormData,
  ...usualPaymentFrequencyInitialFormData,
  ...signUpInitialFormData,
  ...paymentFieldsFormData,
  ...policyConditionsFormData,
  ...productFieldsFormData,
  ...endorsementsFormData,
  ...updateContactDetailsFormData,
  ...protectNoClaimsDiscountFormData,
  ...isAccountHolderFormData,
  ...correlationIdFormData,
  ...quoteDateTimeUtcData,
  ...wasEmailChangedAfterCreationFormData,
};
