import { parseISO, format, isValid, isToday } from 'date-fns';
import { getUKLocalTime } from '@dayinsure/shared';
import {
  AddressDto as AddressDtoV1,
  AdvancedDrivingQualificationDto,
  ContactPreferencesDto,
  CreateMotorQuoteRequestDto,
  DriverDto,
  MarketConsentType,
  MotorClaimDto,
  MotorConvictionDto,
  OccupationModelDto,
  ReferenceCodeDto,
} from '../../api/v1';
import { AddressDto as AddressDtoV3 } from '../../api/v3';
import {
  AccidentOrClaimType,
  AdvancedDrivingQualification as AdvancedDrivingQualificationStateEntry,
  BaseDataEntry,
  DriverFormData,
  MarketingPreferences,
  MotorConviction as MotorConvictionStateEntry,
  NoClaimsHistory,
  Occupation as OccupationStateEntry,
  QuoteJourneyFormData,
  TrackingDevice,
  UsualPaymentFrequency,
  VoluntaryAmounts,
  YesNoDataEntry,
} from '../../types/quoteJourneyForm';
import { filterMapParser, isYes } from '../utils';
import {
  employmentStatusCodeJoblessOcupationMap,
  getStudentOccupationModelDto,
  occupationsCodeTransform,
  STUDENT_EMPLOYMENT_STATUS_CODE,
} from '../forms';
import { getNextSignificantTodayStartTime } from '../quote/getNextSignificantTodayStartTime';

export const parsedDateToISO = (dateString?: string) => {
  if (!dateString) {
    return undefined;
  }

  const parsedDate = parseISO(dateString);

  if (!isValid(parsedDate)) {
    return undefined;
  }

  return format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSxxx");
};

export const parseNumericEntry = (
  entry: BaseDataEntry<string | number> | null
): number | undefined => {
  if (typeof entry?.id === 'string') {
    return parseInt(entry?.id, 10);
  }
  if (typeof entry?.id === 'number') {
    return entry.id;
  }

  return undefined;
};

export const toReferenceCode = (
  entry: BaseDataEntry<string> | null | undefined
): ReferenceCodeDto | undefined =>
  entry
    ? {
        code: entry?.id,
      }
    : undefined;

const toReferenceCodeWithDecline = (
  entry: BaseDataEntry<string> | null | undefined
): ReferenceCodeDto =>
  entry
    ? {
        code: entry?.id,
      }
    : { code: 'NO' };

export const entriesToReferenceCodes = (
  entries: BaseDataEntry<string>[] | null | undefined
): ReferenceCodeDto[] | null =>
  filterMapParser<BaseDataEntry<string>, ReferenceCodeDto>(entries, toReferenceCode);

// TODO: This maps yes/no answer to tracking device code, BE will change to accept yes/no question
const trackingDeviceParser = (trackingDevice: TrackingDevice) =>
  isYes(trackingDevice?.hasTrackingDevice?.id)
    ? toReferenceCode(trackingDevice?.code)
    : undefined;

const vehicleStateParser = ({
  registrationNumber,
  type,
  abiCode,
  make,
  model,
  steeringWheelSide,
  numberOfSeats,
  isImported,
  manufactureYear,
  manufactureStartYear,
  manufactureEndYear,
  bodyType,
  numberOfDoors,
  cubicCapicityInLitres,
  transmission,
  weightInKg,
  primaryFuelType,
  vin,
  trackingDevice,
  selfDeclaredVehicleValue,
  modifications,
  usage,
  ownership,
  location,
  alarmImmobiliser,
}: QuoteJourneyFormData['vehicle']): CreateMotorQuoteRequestDto['vehicle'] => ({
  registrationNumber,
  abiCode: abiCode?.id,
  make: make?.id,
  model: model?.id,
  bodyType: bodyType?.id,
  vin: vin?.id,
  isImported: isYes(isImported?.id),
  numberOfSeats: parseNumericEntry(numberOfSeats),
  manufactureYear: parseNumericEntry(manufactureYear),
  manufactureStartYear: parseNumericEntry(manufactureStartYear),
  manufactureEndYear: parseNumericEntry(manufactureEndYear),
  numberOfDoors: parseNumericEntry(numberOfDoors),
  weightInKg: parseNumericEntry(weightInKg),
  cubicCapicityInLitres: parseNumericEntry(cubicCapicityInLitres),
  transmission: toReferenceCode(transmission),
  primaryFuelType: toReferenceCode(primaryFuelType),
  trackingDevice: trackingDeviceParser(trackingDevice),
  steeringWheelSide: toReferenceCode(steeringWheelSide),
  type: toReferenceCode(type),
  modifications: entriesToReferenceCodes(modifications),
  usage: {
    type: toReferenceCode(usage.type),
    annualMileage: usage.annualMileage || undefined,
    businessMileage: usage.businessMileage || undefined,
  },
  location: {
    daytimeLocation: toReferenceCode(location.daytimeLocation),
    overnightLocation: toReferenceCode(location.overnightLocation),
    overnightLocationPostcode: location.overnightLocationPostcode,
  },
  selfDeclaredVehicleValue: {
    amount: selfDeclaredVehicleValue ?? undefined,
    currency: 'GBP',
  },
  ownership: {
    isPurchased: isYes(ownership.isPurchased?.id),
    selfDeclaredDateOfPurchase: parsedDateToISO(
      ownership.selfDeclaredDateOfPurchase?.parsedDate
    ),
    registeredKeeper: { code: ownership.registerdKeeper?.id },
    legalOwner: { code: ownership.legalOwner?.id },
  },
  alarmImmobiliser: {
    type: { code: alarmImmobiliser?.id, description: alarmImmobiliser?.name },
    model: null,
  },
});

const marketingPreferencesParser = (
  marketingPreferences: MarketingPreferences
): ContactPreferencesDto =>
  marketingPreferences.reduce<ContactPreferencesDto>(
    (result, { id, checked }) => {
      switch (id) {
        case MarketConsentType.EMAIL:
          return {
            ...result,
            allowedToMarketByEmail: checked,
          };
        case MarketConsentType.POST:
          return {
            ...result,
            allowedToMarketByPost: checked,
          };
        case MarketConsentType.SMS:
          return {
            ...result,
            allowedToMarketBySMS: checked,
          };
        case MarketConsentType.TELEPHONE:
          return {
            ...result,
            allowedToMarketByPhone: checked,
          };
        default:
          return result;
      }
    },
    {
      allowedToMarketByEmail: false,
      allowedToMarketByPhone: false,
      allowedToMarketByPost: false,
      allowedToMarketBySMS: false,
    }
  );

const parseAddress = (
  address: AddressDtoV3 | null,
  postcode: string | null
): AddressDtoV1 | undefined => {
  if (!address) {
    return undefined;
  }
  const trimedPostCode = postcode?.replace(/ /g, '');
  return {
    postOfficeAddress: { ...address, postcode: trimedPostCode },
  };
};

const proposerStateParser = ({
  firstName,
  lastName,
  title,
  dateOfBirth,
  maritalStatus,
  address,
  telephoneNumber,
  email,
  childrenUnder16InHousehold,
  isHomeOwner,
  numberOfVehiclesInHousehold,
  marketingPreferences,
  homePostcode,
}: QuoteJourneyFormData['proposer']): CreateMotorQuoteRequestDto['proposer'] => ({
  firstName,
  lastName,
  title: toReferenceCode(title),
  dateOfBirth: parsedDateToISO(dateOfBirth?.parsedDate),
  maritalStatus: toReferenceCode(maritalStatus),
  address: parseAddress(address, homePostcode),
  telephoneNumber: {
    mobile: telephoneNumber.mobile,
    landline: telephoneNumber.landline,
  },
  email,
  childrenUnder16InHousehold: parseNumericEntry(childrenUnder16InHousehold),
  isHomeOwner: isYes(isHomeOwner?.id),
  numberOfVehiclesInHousehold: parseNumericEntry(numberOfVehiclesInHousehold),
  contactPreferences: marketingPreferencesParser(marketingPreferences),
});

const parseOccupation = ({
  type,
  employmentStatus,
  industry,
  isPrimary,
}: OccupationStateEntry) => ({
  type: occupationsCodeTransform(toReferenceCode(type), 3),
  employmentStatus: toReferenceCode(employmentStatus),
  industry: occupationsCodeTransform(toReferenceCode(industry), 3),
  isPrimary,
});

const occupationParser = (
  occupationStateEntry: OccupationStateEntry
): OccupationModelDto => {
  const { employmentStatus, isPrimary } = occupationStateEntry;

  if (
    employmentStatus?.id &&
    employmentStatus.id in employmentStatusCodeJoblessOcupationMap
  ) {
    const occupation = employmentStatusCodeJoblessOcupationMap[employmentStatus.id];
    occupation.isPrimary = isPrimary;
    return occupation;
  }

  if (employmentStatus?.id && employmentStatus.id === STUDENT_EMPLOYMENT_STATUS_CODE) {
    return getStudentOccupationModelDto(occupationStateEntry);
  }

  return parseOccupation(occupationStateEntry);
};

const drivingQualificationParser = (
  entry: AdvancedDrivingQualificationStateEntry
): AdvancedDrivingQualificationDto => ({
  type: toReferenceCode(entry),
});

const motorConvictionParser = ({
  code,
  date,
  disqualifiedLengthInMonths,
  penaltyPoints,
  fineAmount,
}: MotorConvictionStateEntry): MotorConvictionDto => ({
  type: toReferenceCode(code),
  date: parsedDateToISO(date?.parsedDate),
  disqualifiedLengthInMonths: disqualifiedLengthInMonths ?? 0,
  penaltyPoints,
  fineAmount: {
    currency: 'GBP',
    amount: fineAmount,
  },
});

const motorClaimParser = ({
  selfDeclaredClaimType,
  claimDate,
  fault,
  claimSettled,
  claimAmount,
}: AccidentOrClaimType): MotorClaimDto => ({
  source: { code: 'customer' },
  type: toReferenceCode(selfDeclaredClaimType),
  date: parsedDateToISO(claimDate?.parsedDate),
  settled: isYes(claimSettled?.id),
  atFault: isYes(fault?.id),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  damageAmount:
    claimAmount || claimAmount === 0
      ? {
          amount: claimAmount ?? undefined,
          currency: 'GBP',
        }
      : {
          amount: 0,
          currency: 'GBP',
        },
});

const getNoClamsHistory = (noClaimsHistory?: NoClaimsHistory) =>
  noClaimsHistory?.yearsNoClaimsBonus
    ? {
        noClaimsHistory: {
          yearsNoClaimsExperience: parseNumericEntry(noClaimsHistory.yearsNoClaimsBonus),
          yearsNoClaimsBonus: parseNumericEntry(noClaimsHistory.yearsNoClaimsBonus),
        },
      }
    : {};

export const driverStateParser = (
  {
    firstName,
    lastName,
    title,
    maritalStatus,
    dateOfBirth,
    address,
    isProposer,
    drivingLicence,
    relationshipToProposer,
    occupations,
    ukResidencyDetails,
    medicalCondition,
    advancedDrivingQualifications,
    otherVehicleUse,
    motorConvictions,
    hasNonMotoringConvictions,
    accidentsOrClaims,
    isMainDriver,
    homePostcode,
  }: DriverFormData,
  noClaimsHistory?: NoClaimsHistory
): DriverDto => {
  const isMainDriverTypeObject = typeof isMainDriver === 'object';
  return {
    firstName,
    lastName,
    title: toReferenceCode(title),
    maritalStatus: toReferenceCode(maritalStatus),
    dateOfBirth: parsedDateToISO(dateOfBirth?.parsedDate),
    address: parseAddress(address, homePostcode),
    isProposer: isYes(isProposer?.id),
    isMainDriver: isMainDriverTypeObject ? isYes(isMainDriver?.id) : isMainDriver,
    drivingLicence: {
      type: toReferenceCode(drivingLicence?.type),
      number: drivingLicence.number,
      yearsHeld: parseNumericEntry(drivingLicence.yearsHeld),
      monthsHeld: parseNumericEntry(drivingLicence.monthsHeld),
    },
    relationshipToProposer: toReferenceCode(relationshipToProposer),
    occupations: filterMapParser(occupations, occupationParser),
    ukResidencyDetails: {
      isUkResidentSinceBirth: isYes(ukResidencyDetails.isUkResidentSinceBirth?.id),
      ukResidentSince: parsedDateToISO(ukResidencyDetails.ukResidentSince?.parsedDate),
    },
    medicalCondition: toReferenceCode(medicalCondition.code),
    advancedDrivingQualifications: filterMapParser(
      advancedDrivingQualifications.advancedDrivingQualifications,
      drivingQualificationParser
    ),
    otherVehicleUse: toReferenceCodeWithDecline(otherVehicleUse.otherVehicleUseCode),
    ...getNoClamsHistory(noClaimsHistory),
    motorConvictions: filterMapParser(
      motorConvictions.motorConvictions,
      motorConvictionParser
    ),
    hasNonMotoringConvictions: isYes(hasNonMotoringConvictions?.id),
    previousMotorClaims: filterMapParser(
      accidentsOrClaims.selfDeclaredPreviousMotorClaims,
      motorClaimParser
    ),
  };
};

const driversStateParser = (
  drivers: QuoteJourneyFormData['drivers'],
  noClaimsHistory: NoClaimsHistory
): CreateMotorQuoteRequestDto['drivers'] => {
  return filterMapParser(drivers, (driver, index) => {
    if (index === 0) {
      return driverStateParser(driver, noClaimsHistory);
    }
    return driverStateParser(driver);
  });
};

const coverStateParser = ({
  type,
  startDateTimeUtc,
}: QuoteJourneyFormData['cover']): CreateMotorQuoteRequestDto['cover'] => {
  return {
    type: toReferenceCode(type),
    startDateTimeUtc: isToday(getUKLocalTime(startDateTimeUtc?.parsedDate))
      ? getNextSignificantTodayStartTime().toUTCString()
      : parsedDateToISO(startDateTimeUtc?.parsedDate),
  };
};

const excessesStateParser = ({
  voluntaryAmounts,
}: QuoteJourneyFormData['excesses']): CreateMotorQuoteRequestDto['excesses'] => {
  const parsedAmounts = voluntaryAmounts.map((amount: VoluntaryAmounts) => ({
    ...amount,
    total: parseNumericEntry(amount.total),
  }));
  return {
    voluntaryAmounts: parsedAmounts,
  };
};
const previousInsuranceDeclinedOrCancelledParser = (
  hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: YesNoDataEntry | null
) => isYes(hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled?.id);

const usualPaymentFrequencyParser = ({ code }: UsualPaymentFrequency) => ({
  code: code?.id,
});

export const parseFormikStateToQuotePayload = (
  quoteJourneyFormData: QuoteJourneyFormData
): CreateMotorQuoteRequestDto => ({
  vehicle: vehicleStateParser(quoteJourneyFormData.vehicle),
  proposer: proposerStateParser(quoteJourneyFormData.proposer),
  drivers: driversStateParser(
    quoteJourneyFormData.drivers,
    quoteJourneyFormData.cover.noClaimsHistory
  ),
  cover: coverStateParser(quoteJourneyFormData.cover),
  excesses: excessesStateParser(quoteJourneyFormData.excesses),
  hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled:
    previousInsuranceDeclinedOrCancelledParser(
      quoteJourneyFormData.hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled
    ),
  usualPaymentFrequency: usualPaymentFrequencyParser(
    quoteJourneyFormData.usualPaymentFrequency
  ),
  product: quoteJourneyFormData.product,
  protectNoClaimsDiscount: isYes(quoteJourneyFormData.protectNoClaimsDiscount?.id),
  policyCorrelationId: quoteJourneyFormData.policyCorrelationId,
  quoteDateTimeUtc: quoteJourneyFormData.quoteDateTimeUtc,
});
