import * as Yup from 'yup';
import {
  emailRegex,
  combinedRegistrationRegex,
  postcodeRegex,
  validateDrivingLicence,
  uppercaseRegex,
  lowercaseRegex,
  numberRegex,
  symbolRegex,
  isValidPhoneNumber,
  getUKLocalTime,
  isValidContactNumber,
} from '@dayinsure/shared';
import { subYears, subDays, isBefore, addDays, isEqual } from 'date-fns';
import {
  isBusinessUsage,
  isYes,
  isNo,
  getDriverFormData,
  matchQuoteDriversPath,
  getEndorsement208BaseOnEndorsement202,
  isAnnualPaymentPlan,
  isMonthlyPaymentPlan,
} from '../../helpers';
import { QuoteJourneyRoutes } from '../../routes';
import {
  isValidOccupationsAnotherJobRadio,
  isValidOccupationsJobAndIndustry,
  isValidOcupationType,
} from '../../helpers/forms/yupTestForOccupationsArr';
import { getIndexFromPath } from '../../helpers/forms/getIndexFromPath';
import { DriverFormData, YesNoAnswer, YesNoDataEntry } from '../../types';
import { licenceTypesWithNumber } from '../../pages/Quote/YourLicenseAndHistory/Licence/Licence.const';
import { shouldIDisplayVoluntaryExcess } from '../../helpers/quote/cover';
import { getCarManufactureYearDate } from '../../helpers/forms/getCarManufactureYearDate';

const registrationValidationSchema = Yup.object().shape({
  vehicle: Yup.object().shape({
    registrationNumber: Yup.string()
      .matches(combinedRegistrationRegex, {
        message: 'Please enter a valid registration number',
        excludeEmptyString: true,
      })
      .required('Required'),
  }),
});

const vehicleDetailsValidationSchema = Yup.object().shape({
  vehicle: Yup.object().shape({
    steeringWheelSide: Yup.object().nullable().required('Required'),
    numberOfSeats: Yup.object().nullable().required('Required'),
    isImported: Yup.object().nullable().required('Required'),
    trackingDevice: Yup.object()
      .nullable()
      .shape({
        hasTrackingDevice: Yup.object().nullable().required('Required'),
        code: Yup.object()
          .nullable()
          .when(['hasTrackingDevice.id'], {
            is: isYes,
            then: Yup.object().nullable().required('Required'),
          }),
      }),
    alarmImmobiliser: Yup.object().nullable().required('Required'),
    areDetailsCorrect: Yup.bool().oneOf([true], 'Required'),
    selfDeclaredVehicleValue: Yup.number()
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Required')
      .positive('Please check this')
      .integer('Please check this')
      .min(1, 'Please check this')
      .max(1000000, 'Please check this'),
    isCarModified: Yup.object().nullable().required('Required'),
    isAddingModification: Yup.bool().oneOf([false], 'Required'),
    modifications: Yup.array().when('isCarModified.id', {
      is: isYes,
      then: Yup.array()
        .of(Yup.object().nullable().shape({ id: Yup.string(), name: Yup.string() }))
        .min(1, 'Required'),
    }),
    ownership: Yup.object().shape({
      isPurchased: Yup.object().nullable().required('Required'),
      selfDeclaredDateOfPurchase: Yup.object()
        .nullable()
        .required('Required')
        .when(['isPurchased.id'], {
          is: isYes,
          then: Yup.object()
            .nullable()
            .shape({
              parsedDate: Yup.date()
                .nullable()
                .required('Please check this')
                .min(new Date(1935, 0), 'Please check this')
                .max(new Date(), 'Please check this'),
              month: Yup.string().required('Required'),
              year: Yup.string().required('Required'),
            })
            .test(
              'Car purchase date should be after car manufacture date',
              'Please check this',
              (value, ctx) => {
                const manufactureDate = getCarManufactureYearDate(ctx);
                if (!value?.parsedDate || !manufactureDate) {
                  return false;
                }
                return (
                  isBefore(manufactureDate, value.parsedDate) ||
                  isEqual(manufactureDate, value.parsedDate)
                );
              }
            ),
        }),
      registerdKeeper: Yup.object().nullable().required('Required'),
      legalOwner: Yup.object().nullable().required('Required'),
    }),
  }),
});

export const vehicleUsageValidationSchema = Yup.object().shape({
  vehicle: Yup.object().shape({
    usage: Yup.object().shape({
      type: Yup.object().nullable().required('Required'),
      annualMileage: Yup.number()
        .transform(value => (Number.isNaN(value) ? null : value))
        .nullable()
        .required('Required')
        .positive('Please check this')
        .integer('Please check this')
        .min(200, 'Please check this')
        .max(99999, 'Please check this'),
      businessMileage: Yup.number()
        .nullable()
        .when(['type.id'], {
          is: isBusinessUsage,
          then: Yup.number()
            .transform(value => (Number.isNaN(value) ? null : value))
            .nullable()
            .required('Required')
            .positive('Please check this')
            .integer('Please check this')
            .min(200, 'Please check this')
            .max(99999, 'Please check this'),
        }),
    }),
    location: Yup.object().shape({
      keptHome: Yup.object().nullable().required('Required'),
      overnightLocationPostcode: Yup.string()
        .when(['keptHome.id'], {
          is: isNo,
          then: Yup.string().nullable().required('Required'),
        })
        .matches(postcodeRegex, {
          message: 'Please check this',
          excludeEmptyString: true,
        }),
      daytimeLocation: Yup.object().nullable().required('Required'),
      overnightLocation: Yup.object().nullable().required('Required'),
    }),
  }),
});

export const driverDetailsBasicShape = Yup.object().shape({
  title: Yup.object().nullable().required('Required'),
  firstName: Yup.string()
    .min(2, 'Please check this')
    .max(20, 'Please check this')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Please check this')
    .max(20, 'Please check this')
    .required('Required'),
  dateOfBirth: Yup.object()
    .nullable()
    .shape({
      parsedDate: Yup.date()
        .nullable()
        .required('Please check this')
        .min(subYears(subDays(getUKLocalTime(), 1), 100), 'Please check this')
        .max(subYears(getUKLocalTime(), 16), 'Please check this'),
      day: Yup.string().required('Required'),
      month: Yup.string().required('Required'),
      year: Yup.string().required('Required'),
    })
    .required('Required'),
  maritalStatus: Yup.object().nullable().required('Required'),
  ukResidencyDetails: Yup.object().shape({
    isUkResidentSinceBirth: Yup.object().nullable().required('Required'),
    ukResidentSince: Yup.object()
      .nullable()
      .when(['isUkResidentSinceBirth.id'], {
        is: isNo,
        then: Yup.object()
          .nullable()
          .required('Required')
          .shape({
            parsedDate: Yup.date()
              .nullable()
              .required('Please check this')
              .max(new Date(), 'Please check this'),
            month: Yup.string().required('Required'),
            year: Yup.string().required('Required'),
          }),
      })
      .test(
        'Date moved to UK is less than date of user birth',
        'Please check this',
        (value, ctx) => {
          const index = getIndexFromPath(ctx, 'drivers');
          const driverFormValues = getDriverFormData(ctx, index);
          if (!driverFormValues) {
            return true;
          }

          if (isYes(driverFormValues.ukResidencyDetails.isUkResidentSinceBirth?.id)) {
            return true;
          }

          if (!value?.parsedDate || !driverFormValues?.dateOfBirth?.parsedDate) {
            return true;
          }

          const dob = new Date(
            parseInt(driverFormValues.dateOfBirth?.year, 10),
            parseInt(driverFormValues.dateOfBirth?.month, 10),
            parseInt(driverFormValues.dateOfBirth?.day, 10)
          );
          const dateOfMovingToUK = new Date(value?.year, value?.month, value?.day);
          return isBefore(dob, dateOfMovingToUK);
        }
      ),
  }),
  occupations: Yup.array().of(
    Yup.object().shape({
      employmentStatus: Yup.object()
        .nullable()
        .test(
          'In second array element employmentStatus not exists',
          'Required',
          (value, ctx) => {
            if (Object.keys(ctx.parent).includes('employmentStatus')) {
              return !!value;
            }
            return true;
          }
        ),
      type: Yup.object()
        .nullable()
        .test(
          'Second element of array depends on first element, there is possibility that job and industry are only in no2',
          'Required',
          isValidOcupationType
        ),
      industry: Yup.object()
        .nullable()
        .test(
          'Second element of array depends on first element, there is possibility that job and industry are only in no2',
          'Required',
          isValidOccupationsJobAndIndustry
        ),
      anotherJob: Yup.object()
        .nullable()
        .test(
          'If option is voluntary work, is primary is required',
          'Required',
          isValidOccupationsAnotherJobRadio
        ),
    })
  ),
});
const yourDetailsValidationSchema = Yup.object().shape({
  drivers: Yup.array().of(driverDetailsBasicShape),
  proposer: Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, {
        message: 'Please check this',
        excludeEmptyString: true,
      })
      .required('Required'),
    telephoneNumber: Yup.object().shape({
      landline: Yup.string().test('phone-num', 'Please check this', (value, ctx) => {
        const {
          parent: { mobile },
          createError,
        } = ctx;

        if (mobile && !value) {
          return true;
        }
        if (!value) {
          return createError({ message: 'Required' });
        }
        return isValidContactNumber(value);
      }),
      mobile: Yup.string().test('phone-num', 'Please check this', (value, ctx) => {
        const {
          parent: { landline },
          createError,
        } = ctx;
        if (landline && !value) {
          return true;
        }

        if (!value) {
          return createError({ message: 'Required' });
        }
        return isValidPhoneNumber(value);
      }),
    }),
  }),
  wasEmailChangedAfterCreation: Yup.boolean().nullable(),
});

const driversValidationSchema = Yup.object().shape({
  mainDriver: Yup.object()
    .nullable()
    .when(['drivers'], {
      is: (drivers: DriverFormData[]) => drivers.length > 1,
      then: Yup.object().nullable().required('Required'),
    }),
});

export const additionalDriverDetailsValidationSchema = driverDetailsBasicShape.concat(
  Yup.object().shape({
    relationshipToProposer: Yup.object().nullable().required('Required'),
  })
);
const yourHomeValidationSchema = Yup.object().shape({
  drivers: Yup.array().of(
    Yup.object().shape({
      homePostcode: Yup.string()
        .nullable()
        .when(['isProposer.id'], {
          is: isYes,
          then: Yup.string().nullable().required('Required').matches(postcodeRegex, {
            message: 'Please check this',
            excludeEmptyString: true,
          }),
        }),
      homeAddress: Yup.object()
        .nullable()
        .when(['isProposer.id'], {
          is: isYes,
          then: Yup.object().nullable().required('Required'),
        }),
    })
  ),
  proposer: Yup.object().shape({
    isHomeOwner: Yup.object().nullable().required('Required'),
    numberOfVehiclesInHousehold: Yup.object().nullable().required('Required'),
    childrenUnder16InHousehold: Yup.object().nullable().required('Required'),
  }),
});

export const accidentOrClaimSchema = Yup.object().shape({
  selfDeclaredClaimType: Yup.object().nullable().required('Required'),
  claimDate: Yup.object()
    .nullable()
    .shape({
      day: Yup.string().required('Required'),
      month: Yup.string().required('Required'),
      year: Yup.string().required('Required'),
      parsedDate: Yup.date()
        .nullable()
        .required('Please check this')
        .min(subYears(subDays(new Date(), 1), 5), 'Please check this')
        .max(new Date(), 'Please check this'),
    })
    .required('Required'),
  fault: Yup.object().nullable().required('Required'),
  claimSettled: Yup.object().nullable().required('Required'),
  knowClaimAmount: Yup.object().nullable().required('Required'),
  claimAmount: Yup.number()
    .nullable()
    .when('knowClaimAmount.id', {
      is: isYes,
      then: Yup.number()
        .transform(value => (Number.isNaN(value) ? null : value))
        .nullable()
        .required('Required')
        .positive('Please check this')
        .integer('Please check this')
        .min(0, 'Please check this')
        .max(100000000, 'Please check this'),
    }),
});

export const motorConvictionsSchema = Yup.object().shape({
  code: Yup.object().nullable().required('Required'),
  date: Yup.object()
    .nullable()
    .shape({
      day: Yup.string().required('Required'),
      month: Yup.string().required('Required'),
      year: Yup.string().required('Required'),
      parsedDate: Yup.date()
        .nullable()
        .required('Please check this')
        .min(subYears(subDays(new Date(), 1), 5), 'Please check this')
        .max(new Date(), 'Please check this'),
    })
    .required('Required'),
  penaltyPoints: Yup.number()
    .nullable()
    .required('Required')
    .min(0, 'Please check this')
    .max(36, 'Please check this'),
  fineAmount: Yup.number()
    .nullable()
    .required('Required')
    .min(0, 'Please check this')
    .max(100000000, 'Please check this'),
  banReceived: Yup.object().nullable().required('Required'),
  disqualifiedLengthInMonths: Yup.number()
    .nullable()
    .when(['banReceived.id'], {
      is: isYes,
      then: Yup.number()
        .transform(value => (Number.isNaN(value) ? null : value))
        .nullable()
        .required('Required')
        .positive('Please check this')
        .integer('Please check this')
        .min(1, 'Please check this')
        .max(120, 'Please check this'),
    }),
});

export const yourLicenceAndHistoryValidationObject = Yup.object().shape({
  drivingLicence: Yup.object().shape({
    provideLicence: Yup.object().when('type', {
      is: (type: { id: string; name: string }) => {
        return type && licenceTypesWithNumber.includes(type.id);
      },
      then: Yup.object().nullable().required('Required'),
      otherwise: Yup.object().nullable(),
    }),
    number: Yup.string().when(['type', 'provideLicence.id'], {
      is: (type: { id: string; name: string }, provideLicenceId: YesNoAnswer) => {
        return (
          type && licenceTypesWithNumber.includes(type.id) && isYes(provideLicenceId)
        );
      },
      then: Yup.string()
        .min(16, 'Please check this')
        .max(16, 'Please check this')
        .required('Required')
        .test('licence_num', 'Please check this', (value, ctx) => {
          const index = getIndexFromPath(ctx, 'drivers');
          const driverFormValues = getDriverFormData(ctx, index);
          if (!driverFormValues) {
            return true;
          }
          return validateDrivingLicence(
            driverFormValues.firstName,
            driverFormValues.lastName,
            driverFormValues.dateOfBirth?.parsedDate ?? '',
            value
          );
        }),
    }),
    type: Yup.object().nullable().required('Required'),
    yearsHeld: Yup.object().nullable().required('Required'),
    monthsHeld: Yup.object()
      .nullable()
      .when(['yearsHeld.id'], {
        is: (id?: string) => id === '0',
        then: Yup.object().nullable().required('Required'),
      }),
  }),
  otherVehicleUse: Yup.object().shape({
    otherVehicleUse: Yup.object().nullable().required('Required'),
    otherVehicleUseCode: Yup.object()
      .nullable()
      .when(['otherVehicleUse.id'], {
        is: isYes,
        then: Yup.object().nullable().required('Required'),
      }),
  }),
  advancedDrivingQualifications: Yup.object().shape({
    hasAdvancedDrivingQualifications: Yup.object().nullable().required('Required'),
    advancedDrivingQualifications: Yup.array().of(
      Yup.object().nullable().required('Required')
    ),
  }),
  medicalCondition: Yup.object().shape({
    hasMedicalConditions: Yup.object().nullable().required('Required'),
    code: Yup.object()
      .nullable()
      .when(['hasMedicalConditions.id'], {
        is: isYes,
        then: Yup.object().nullable().required('Required'),
      }),
  }),
  accidentsOrClaims: Yup.object().shape({
    hasAccidentOrClaimInLast5Years: Yup.object().nullable().required('Required'),
    selfDeclaredPreviousMotorClaims: Yup.array().of(
      Yup.object({
        isSaved: Yup.bool().oneOf([true], 'This is required'),
      }).concat(accidentOrClaimSchema)
    ),
  }),
  motorConvictions: Yup.object().shape({
    hasMotorConvictions: Yup.object().nullable().required('Required'),
    motorConvictions: Yup.array().of(
      Yup.object({
        isSaved: Yup.bool().oneOf([true], 'This is required'),
      }).concat(motorConvictionsSchema)
    ),
  }),
  hasNonMotoringConvictions: Yup.object().nullable().required('Required'),
});

export const yourLicenceAndHistoryValidationForProposerObject = Yup.object().shape({
  drivingLicence: Yup.object().when('index', {
    is: (i: number) => i === 0,
    then: Yup.object().shape({
      provideLicence: Yup.object().when('type', {
        is: (type: { id: string; name: string }) => {
          return type && licenceTypesWithNumber.includes(type.id);
        },
        then: Yup.object().nullable().required('Required'),
        otherwise: Yup.object().nullable(),
      }),
      number: Yup.string().when(['type', 'provideLicence.id'], {
        is: (type: { id: string; name: string }, provideLicenceId: YesNoAnswer) => {
          return (
            type && licenceTypesWithNumber.includes(type.id) && isYes(provideLicenceId)
          );
        },
        then: Yup.string()
          .min(16, 'Please check this')
          .max(16, 'Please check this')
          .required('Required')
          .test('licence_num', 'Please check this', (value, ctx) => {
            const index = getIndexFromPath(ctx, 'drivers');
            const driverFormValues = getDriverFormData(ctx, index);
            if (!driverFormValues) {
              return true;
            }
            return validateDrivingLicence(
              driverFormValues.firstName,
              driverFormValues.lastName,
              driverFormValues.dateOfBirth?.parsedDate ?? '',
              value
            );
          }),
      }),
      type: Yup.object().nullable().required('Required'),
      yearsHeld: Yup.object().nullable().required('Required'),
      monthsHeld: Yup.object()
        .nullable()
        .when(['yearsHeld.id'], {
          is: (id?: string) => id === '0',
          then: Yup.object().nullable().required('Required'),
        }),
    }),
  }),
  otherVehicleUse: Yup.object().when('index', {
    is: (i: number) => i === 0,
    then: Yup.object().shape({
      otherVehicleUse: Yup.object().nullable().required('Required'),
      otherVehicleUseCode: Yup.object()
        .nullable()
        .when(['otherVehicleUse.id'], {
          is: isYes,
          then: Yup.object().nullable().required('Required'),
        }),
    }),
  }),
  advancedDrivingQualifications: Yup.object().when('index', {
    is: (i: number) => i === 0,
    then: Yup.object().shape({
      hasAdvancedDrivingQualifications: Yup.object().nullable().required('Required'),
      advancedDrivingQualifications: Yup.array().of(
        Yup.object().nullable().required('Required')
      ),
    }),
  }),
  medicalCondition: Yup.object().when('index', {
    is: (i: number) => i === 0,
    then: Yup.object().shape({
      hasMedicalConditions: Yup.object().nullable().required('Required'),
      code: Yup.object()
        .nullable()
        .when(['hasMedicalConditions.id'], {
          is: isYes,
          then: Yup.object().nullable().required('Required'),
        }),
    }),
  }),
  accidentsOrClaims: Yup.object().when('index', {
    is: (i: number) => i === 0,
    then: Yup.object().shape({
      hasAccidentOrClaimInLast5Years: Yup.object().nullable().required('Required'),
      selfDeclaredPreviousMotorClaims: Yup.array().of(
        Yup.object({
          isSaved: Yup.bool().oneOf([true], 'This is required'),
        }).concat(accidentOrClaimSchema)
      ),
    }),
  }),
  motorConvictions: Yup.object().when('index', {
    is: (i: number) => i === 0,
    then: Yup.object().shape({
      hasMotorConvictions: Yup.object().nullable().required('Required'),
      motorConvictions: Yup.array().of(
        Yup.object({
          isSaved: Yup.bool().oneOf([true], 'This is required'),
        }).concat(motorConvictionsSchema)
      ),
    }),
  }),
  hasNonMotoringConvictions: Yup.object().when('index', {
    is: (i: number) => i === 0,
    then: Yup.object().nullable().required('Required'),
  }),
});

const yourLicenceAndHistoryValidationSchema = Yup.object().shape({
  drivers: Yup.array().of(yourLicenceAndHistoryValidationForProposerObject),
});

const coverValidationSchema = Yup.object().shape({
  protectNoClaimsDiscount: Yup.object()
    .nullable()
    .when(['cover.noClaimsHistory.yearsNoClaimsBonus.id'], {
      is: (years: string) => years && years !== '0',
      then: Yup.object().nullable().required('Required'),
    }),
  cover: Yup.object().shape({
    startDateTimeUtc: Yup.object()
      .nullable()
      .shape({
        parsedDate: Yup.date()
          .nullable()
          .required('Please check this')
          .min(subDays(new Date(), 1), 'Please check this')
          .max(addDays(new Date(), 30), 'Please check this'),
        day: Yup.string().required('Required'),
        month: Yup.string().required('Required'),
        year: Yup.string().required('Required'),
      })
      .required('Required'),
    type: Yup.object().nullable().required('Required'),
    noClaimsHistory: Yup.object().shape({
      yearsNoClaimsBonus: Yup.object().nullable().required('Required'),
    }),
  }),
  excesses: Yup.object()
    .nullable()
    .when(['cover.type.id'], {
      is: (type: string) => shouldIDisplayVoluntaryExcess(type),
      then: Yup.object()
        .nullable()
        .shape({
          voluntaryAmounts: Yup.array().of(
            Yup.object().shape({
              total: Yup.object().nullable().required('Required'),
              type: Yup.object().shape({
                code: Yup.string().nullable().required('Required'),
              }),
            })
          ),
        }),
    }),
  usualPaymentFrequency: Yup.object().shape({
    code: Yup.object().nullable().required('Required'),
  }),
  hasAnyDriverHadPreviousInsuranceDeclinedOrCancelled: Yup.object()
    .nullable()
    .required('Required'),
});

export const carSecurityValidationSchema = Yup.object().shape({
  carSecurity: Yup.object().shape({
    isEndorsement202: Yup.bool(),
    isEndorsement208: Yup.bool(),
    approvedTracker: Yup.object()
      .nullable()
      .when(['isEndorsement202'], {
        is: (isEndorsement202: boolean) => isEndorsement202,
        then: Yup.object().nullable().required('Required'),
      }),
    antiCloneImmobiliser: Yup.object()
      .nullable()
      .when(['isEndorsement208', 'isEndorsement202', 'approvedTracker.id'], {
        is: (
          isEndorsement208: boolean,
          isEndorsement202: boolean,
          approvedTrackerId?: YesNoAnswer
        ) =>
          isEndorsement208 &&
          getEndorsement208BaseOnEndorsement202(isEndorsement202, approvedTrackerId),
        then: Yup.object().nullable().required('Required'),
      }),
    immobiliser: Yup.object()
      .nullable()
      .when(['antiCloneImmobiliser.id'], {
        is: isYes,
        then: Yup.object().nullable().required('Required'),
      }),
    installationProof: Yup.object()
      .nullable()
      .when(['immobiliser.id'], {
        is: (immobiliserId?: string) => immobiliserId && immobiliserId !== '5',
        then: Yup.object().nullable().required('Required'),
      }),
  }),
});

const yourQuoteValidationSchema = Yup.object().shape({
  cover: Yup.object().shape({
    paymentType: Yup.object().required('Required'),
    addOns: Yup.array().required('Required'),
    breakdownAddon: Yup.object()
      .nullable()
      .when(['optionalAddOnsValidation'], {
        is: (optionalAddOns: (YesNoDataEntry | null)[]) => {
          if (optionalAddOns.length === 0) {
            return false;
          }

          const breakdownOptionalAddOn = optionalAddOns.at(-1);
          return isYes(breakdownOptionalAddOn?.id);
        },
        then: Yup.object().nullable().required('Required'),
      }),
    optionalAddOnsValidation: Yup.array().of(
      Yup.object().required('Required').nullable()
    ),
  }),
});

const signupValidationSchema = Yup.object().when('isCreateAccountDisplayed', {
  is: true,
  then: Yup.object({
    password: Yup.string()
      .min(8, 'Too short')
      .matches(uppercaseRegex, {
        message: 'One uppercase letter',
        excludeEmptyString: true,
      })
      .matches(lowercaseRegex, {
        message: 'One lowercase letter',
        excludeEmptyString: true,
      })
      .matches(numberRegex, {
        message: 'One number',
        excludeEmptyString: true,
      })
      .matches(symbolRegex, {
        message: 'One symbol',
        excludeEmptyString: true,
      })
      .required('Required'),
    retypePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match')
      .required('Required'),
    reaptcha: Yup.string().required('Please check this'),
  }),
});

const reviewValidationSchema = Yup.object({
  isCreateAccountDisplayed: Yup.bool(),
  isAccountCreated: Yup.bool(),
  proposer: Yup.object().shape({}),
}).concat(Yup.object().shape({ signUp: signupValidationSchema }));

const cardFieldValidation = Yup.string()
  .nullable()
  .when(['usualPaymentFrequency.code', 'isDirectDebitConfirmed'], {
    is: (code: { id?: string }, isDirectDebitConfirmed: boolean) => {
      return isAnnualPaymentPlan(code?.id) || isDirectDebitConfirmed;
    },
    then: Yup.string().nullable().required('Required'),
  });

const paymentValidationSchema = Yup.object().shape({
  encryptedCardNumber: cardFieldValidation,
  cardholderName: cardFieldValidation,
  encryptedExpiryMonth: cardFieldValidation,
  encryptedExpiryYear: cardFieldValidation,
  encryptedSecurityCode: cardFieldValidation,
  paymentDay: Yup.object()
    .nullable()
    .when(['usualPaymentFrequency.code'], {
      is: (code: { id?: string }) => isMonthlyPaymentPlan(code?.id),
      then: Yup.object().nullable().required('Required'),
    }),
  accountHolder: Yup.string().when(['usualPaymentFrequency.code'], {
    is: (code: { id?: string }) => isMonthlyPaymentPlan(code?.id),
    then: Yup.string()
      .min(2, 'Please check this')
      .max(20, 'Please check this')
      .nullable()
      .required('Required'),
  }),
  bankAccountNumber: Yup.string().when(['usualPaymentFrequency.code'], {
    is: (code: { id?: string }) => isMonthlyPaymentPlan(code?.id),
    then: Yup.string()
      .min(8, 'Please check this')
      .max(8, 'Please check this')
      .max(20, 'Please check this')
      .nullable()
      .required('Required'),
  }),
  sortCode: Yup.string().when(['usualPaymentFrequency.code'], {
    is: (code: { id?: string }) => isMonthlyPaymentPlan(code?.id),
    then: Yup.string()
      .test('max 6 digits', 'Please check this', val =>
        val ? val.split('').filter((char: string) => char !== ' ').length > 5 : true
      )
      .nullable()
      .required('Required'),
  }),
  isAccountHolder: Yup.object()
    .nullable()
    .when(['usualPaymentFrequency.code'], {
      is: (code: { id?: string }) => isMonthlyPaymentPlan(code?.id),
      then: Yup.object().nullable().required('Required'),
    }),
  isDirectDebitConfirmed: Yup.boolean(),
  isMonthlyImportantInformationConfirmed: Yup.boolean().when(
    ['usualPaymentFrequency.code'],
    {
      is: (code: { id?: string }) => isMonthlyPaymentPlan(code?.id),
      then: Yup.boolean().oneOf([true], 'Required'),
    }
  ),
  isAnnualImportantInformationConfirmed: Yup.boolean().when(
    ['usualPaymentFrequency.code'],
    {
      is: (code: { id?: string }) => isAnnualPaymentPlan(code?.id),
      then: Yup.boolean().oneOf([true], 'Required'),
    }
  ),
});

export const getValidationSchemaFromRoute = (route: string) => {
  if (route.includes(QuoteJourneyRoutes.RegistrationSearch)) {
    return registrationValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.CarDetails)) {
    return vehicleDetailsValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.CarUsage)) {
    return vehicleUsageValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.YourDetails)) {
    return yourDetailsValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.YourHome)) {
    return yourHomeValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.YourLicenceAndHistory)) {
    return yourLicenceAndHistoryValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.Drivers)) {
    return driversValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.Cover)) {
    return coverValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.CarSecurity)) {
    return carSecurityValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.YourQuote)) {
    return yourQuoteValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.Review)) {
    return reviewValidationSchema;
  }
  if (route.includes(QuoteJourneyRoutes.Payment)) {
    return paymentValidationSchema;
  }
  return undefined;
};

export const getValidationSchemaFromAdditionalDriverRoute = (route: string) => {
  const matchAdditionalDriverDetailsRoute = matchQuoteDriversPath(
    route,
    QuoteJourneyRoutes.DriverDetails
  );
  const matchAdditionalDriverLicenceRoute = matchQuoteDriversPath(
    route,
    QuoteJourneyRoutes.DriverLicenceAndHistory
  );

  if (matchAdditionalDriverDetailsRoute) {
    return additionalDriverDetailsValidationSchema;
  }
  if (matchAdditionalDriverLicenceRoute) {
    return yourLicenceAndHistoryValidationObject;
  }
  return undefined;
};
