import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import AdyenCheckout from '@adyen/adyen-web';
import { QuoteJourneyFormData } from '../../types';
import { PaymentContext } from '../../contexts/PaymentContext';
import { useResumePaymentMutation } from '../mutations/useResumePaymentMutation/useResumePaymentMutation';
import { QuoteJourneyStatusContext } from '../../contexts';
import { useGlobalError } from '../useGlobalError';
import {
  AdditionalDetailsStateType,
  EncryptedErrors,
  EncryptedFields,
  FormConfigSuccess,
} from './checkout.types';
import { usePaymentProcessing } from '../usePaymentProcessing';
import { DEFAULT_ERROR_STATES, DEFAULT_FOCUS_STATES } from '../../constants/payments';

type CardErrors = {
  encryptedCardNumber?: string;
  encryptedExpiryYear?: string;
  encryptedExpiryMonth?: string;
  encryptedSecurityCode?: string;
};
const pleaseCheckThisValidationCodes = [
  'error.va.sf-cc-dat.02',
  'error.va.sf-cc-dat.01',
  'error.va.sf-cc-num.01',
];

const requiredValidationCodes = [
  'error.va.sf-cc-num.04',
  'error.va.sf-cc-cvc.02',
  'error.va.sf-cc-yr.02',
];

const cardBranNotSupportedCode = 'error.va.sf-cc-num.03';
export const UNSUPPORTED_CARD_BRAND_ERROR_MESSAGE =
  "We don't accept this card type. Please use another card.";

export const usePaymentCheckout = (mta?: boolean) => {
  const { setCheckoutContext, checkoutContext, orderId, setOrderId, setCardFormVisible } =
    useContext(PaymentContext);
  const resumeOrderMutation = useResumePaymentMutation(orderId || '');
  const { setFieldTouched, setFieldValue } = useFormikContext<QuoteJourneyFormData>();
  const [paymentFocusStates, setPaymentFocusStates] = useState(DEFAULT_FOCUS_STATES);
  const [paymentErrorStates, setPaymentErrorStates] =
    useState<CardErrors>(DEFAULT_ERROR_STATES);
  const [mounted, setMounted] = useState(false);
  const [isConfigSuccess, setIsConfigSuccess] = useState(false);
  const { setIsLoadingState } = useContext(QuoteJourneyStatusContext);
  const { showGlobalError } = useGlobalError();
  const { processPayment } = usePaymentProcessing(mta);

  const handleError = useCallback((errors: EncryptedErrors) => {
    const errorsObject = Object.keys(errors).reduce((res: CardErrors, key: string) => {
      const errorCode = errors[key]?.error;
      if (errorCode && pleaseCheckThisValidationCodes.includes(errorCode)) {
        return { ...res, [key]: 'Please check this' };
      }
      if (errorCode && requiredValidationCodes.includes(errorCode)) {
        return { ...res, [key]: 'Required' };
      }
      if (errorCode && errorCode === cardBranNotSupportedCode) {
        return {
          ...res,
          [key]: UNSUPPORTED_CARD_BRAND_ERROR_MESSAGE,
        };
      }
      return { ...res, [key]: errors[key]?.errorI18n };
    }, {});
    setPaymentErrorStates(errorsObject);
  }, []);

  const handleChange = useCallback(
    (data: { paymentMethod: EncryptedFields }) => {
      setFieldValue(
        'encryptedCardNumber',
        data?.paymentMethod?.encryptedCardNumber || null
      );
      setFieldValue(
        'encryptedExpiryMonth',
        data?.paymentMethod?.encryptedExpiryMonth || null
      );
      setFieldValue(
        'encryptedExpiryYear',
        data?.paymentMethod?.encryptedExpiryYear || null
      );
      setFieldValue(
        'encryptedSecurityCode',
        data?.paymentMethod?.encryptedSecurityCode || null
      );
    },
    [setFieldValue]
  );
  const onChange = useCallback(
    ({
      data,
      errors,
    }: {
      data: { paymentMethod: EncryptedFields };
      errors: EncryptedErrors;
    }) => {
      handleError(errors);
      handleChange(data);
    },
    [handleChange, handleError]
  );
  const handleFocus = useCallback(
    (e: { fieldType: string; focus: boolean }) => {
      if (!e.focus) {
        setPaymentFocusStates(DEFAULT_FOCUS_STATES);
        setFieldTouched(`${e.fieldType}`);
      } else {
        setPaymentFocusStates({
          encryptedCardNumber: e.fieldType === 'encryptedCardNumber',
          encryptedExpiryDate: e.fieldType === 'encryptedExpiryDate',
          encryptedExpiryMonth: e.fieldType === 'encryptedExpiryMonth',
          encryptedExpiryYear: e.fieldType === 'encryptedExpiryYear',
          encryptedSecurityCode: e.fieldType === 'encryptedSecurityCode',
        });
      }
    },
    [setFieldTouched]
  );
  const customCard = useMemo(() => {
    if (checkoutContext) {
      return checkoutContext.create('securedfields', {
        type: 'card',
        brands: ['mc', 'visa'],
        styles: {
          base: {
            color: `rgba(${getComputedStyle(document.body).getPropertyValue(
              '--main-content-1'
            )})`,
            fontFamily: 'Helvetica',
            fontWeight: '300',
            lineHeight: '60px',
          },
          error: {
            color: `rgba(${getComputedStyle(document.body).getPropertyValue(
              '--main-content-1'
            )})`,
            fontFamily: 'Helvetica',
            fontWeight: '300',
          },
          placeholder: {
            color: `rgba(${getComputedStyle(document.body).getPropertyValue(
              '--main-content-2'
            )})`,
            fontFamily: 'Helvetica',
            fontWeight: '300',
          },
          validated: {
            color: `rgba(${getComputedStyle(document.body).getPropertyValue(
              '--main-content-1'
            )})`,
            fontFamily: 'Helvetica',
            fontWeight: '300',
          },
        },
        onChange,
        onFocus: handleFocus,
        onConfigSuccess: ({ iframesConfigured }: FormConfigSuccess) => {
          setIsConfigSuccess(iframesConfigured);
          setFieldTouched('encryptedCardNumber', false);
          setFieldTouched('cardholderName', false);
          setFieldTouched('encryptedExpiryDate', false);
          setFieldTouched('encryptedExpiryMonth', false);
          setFieldTouched('encryptedExpiryYear', false);
          setFieldTouched('encryptedSecurityCode', false);
        },
      });
    }
    return null;
  }, [checkoutContext, handleFocus, onChange, setFieldTouched]);

  const mountCustomCard = useCallback(() => {
    try {
      if (customCard && !mounted) {
        customCard?.mount('#customCard-container');
      }
    } catch (error) {
      console.warn(
        'Component attempted to mount twice (this is expected in strict mode)'
      );
    }
  }, [customCard, mounted]);

  const handleSetError = useCallback(
    (error: unknown) => {
      showGlobalError(error);
      setOrderId(null);
      setCardFormVisible(true);
      setIsLoadingState(false);
    },
    [setCardFormVisible, setIsLoadingState, setOrderId, showGlobalError]
  );

  const onAdditionalDetails = useCallback(
    (state: AdditionalDetailsStateType) => {
      const {
        data: { details },
      } = state;
      const payload = {
        actionType: 'threeDS2',
        actionResult: details?.threeDSResult || '',
      };
      resumeOrderMutation.mutate(payload, {
        onSuccess: processPayment,
        onError: handleSetError,
      });
    },
    [handleSetError, processPayment, resumeOrderMutation]
  );

  const configuration = useMemo(
    () => ({
      locale: process.env.REACT_APP_ADYEN_LOCALE,
      environment: process.env.REACT_APP_ADYEN_ENVIROMENT,
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      translations: {
        'en-GB': {
          'creditCard.numberField.placeholder': 'Enter 16 digits',
        },
      },
      onAdditionalDetails,
    }),
    [onAdditionalDetails]
  );

  useEffect(() => {
    if (!mounted && checkoutContext) {
      setMounted(true);
      mountCustomCard();
    }
    if (!checkoutContext && !mounted) {
      (async () => {
        setCheckoutContext(await AdyenCheckout(configuration));
      })();
    }
    return () => setCardFormVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, checkoutContext]);

  return {
    checkout: checkoutContext,
    customCard,
    paymentErrorStates,
    paymentFocusStates,
    isConfigSuccess,
    setCheckoutContext,
  };
};
