import { Card } from '@dayinsure/components';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useEndorsementsQuery, useQuoteQuery, usePolicyQuery } from '../../../hooks';
import { getQuoteEndorsements } from '../../../helpers';
import { filterEndorsementByCodes } from '../../../helpers/forms/filterEndorsementByCodes';

type ConditionsCardProps = {
  conditionsCodesToDisplay?: string[];
  testId: string;
};
export const ConditionsCard = ({
  conditionsCodesToDisplay,
  testId,
}: ConditionsCardProps) => {
  const { quoteId, id } = useParams<{ quoteId: string; id: string }>();
  const { data: quote } = useQuoteQuery(quoteId);
  const { data: policy } = usePolicyQuery(id);
  const { data } = useEndorsementsQuery();
  const endorsementsList = data || [];
  const endorsements = quote?.endorsements || policy?.endorsements;
  const endorsementsExtendedList = filterEndorsementByCodes(
    getQuoteEndorsements(endorsementsList, endorsements || []),
    conditionsCodesToDisplay
  );

  const renderEndorsements = useMemo(
    () =>
      endorsementsExtendedList.map(({ description, type, code }) => (
        <div
          key={code}
          className="mt-6 font-lato text-sm leading-[18px]"
          data-testid={`${testId}_endorsement_${code}`}
        >
          <p className="font-bold">{type}</p>
          <p>{description}</p>
        </div>
      )),
    [endorsementsExtendedList, testId]
  );
  return (
    <>
      {endorsementsList.length > 0 && (
        <Card
          paddingLevel="small"
          classNames={clsx('mt-6 lg:mt-8 bg-content-background-prominent', {
            'mb-6 lg:mb-8': !!policy,
          })}
        >
          <h2 className="text-lg">Policy endorsements</h2>
          {renderEndorsements}
        </Card>
      )}
    </>
  );
};
